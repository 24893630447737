import React, { Component } from 'react';
import './AlarmForm.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import whatsappUrl from 'whatsapp-url'
import HeaderComponent from './HeaderComponent';
import FormItem from './FormItem'

class AlarmForm extends Component {
    state = {
        doors:0,
        windows:0,
        sensors:0,
        meters:1,
        comunication:1,
        keyboard:0,
        control:0,
        app:0,
        siren:0,
        link:'',
    }

    generateBudget = () => {
        const { doors, windows, sensors, meters, comunication, keyboard, control, siren } = this.state
        let price = doors * (164.38 + 25)
        price += windows * (164.38 + 25)
        price += sensors * (267.42 + 25)

        if (meters === 2) {
            price += 360.70
        }
        if (meters === 3) {
            price += 2 * 360.70
        }
        if (meters === 4) {
            price += 3 * 360.70
        }
        if (comunication === 1) {
            price += 236.78
        }
        if (comunication === 3) {
            price += 1242.47
        }
        if (comunication === 4) {
            price += 713.44
        }

        price += 531.85 * keyboard
        price += 113.92 * control
        price += 378.44 * siren
        // Central
        price += 1187.29

        return price.toFixed(2)
    }

    generateBudgetMessage = () => {
        const { doors, windows, sensors, meters, comunication, keyboard, control, app, siren } = this.state
        let message = 'Orçamento para Alarme Monitorado'
        message += '%0APortas: ' + doors
        message += '%0AJanelas: ' + windows
        message += '%0ASensores: ' + sensors
        message += '%0AÁrea protegida (m²): '
        if (meters === 1) {
             message += '0 a 200 metros'
        }
        if (meters === 2) {
            message += '200 a 400 metros'
        }
        if (meters === 3) {
            message += '400 a 600 metros'
        }
        if (meters === 4) {
            message += 'De 600 para cima'
        }
        message += '%0AComunicação : ' 
        if (comunication === 1) {
            message += 'Linha telefônica do local'
        }
        if (comunication === 2) {
            message += 'Internet do local'
        }
        if (comunication === 3) {
        message += 'Rede de rádios DIVS segurança'
        }
        if (comunication === 4) {
            message += 'GPRS - Chip DIVS'
        }
        message += '%0ATeclado: ' + keyboard
        message += '%0AControle Remoto para arme e desarme: ' + control
        message += '%0AApp para ativação e desativação do sistema: ' + app 
        message += '%0ASirene: ' + siren

        const price = this.generateBudget()
        message += '%0AValor total: R$ ' + price

        const link = whatsappUrl({phone: process.env.REACT_APP_PHONE_NUMBER, text: message})
        window.open(link)
    }

    changeFormItem = (field, value) => {
        this.setState(state => {
            return {
                [field]: value
            }
        })
    }

    changeMetersValue = (value) => {
        this.setState({
            meters: value
        })
    }

    changeComunicationValue = (value) => {
        this.setState({
            comunication: value
        })
    }

    render() {
        const { doors, windows, sensors, meters, comunication, keyboard, control, app, siren } = this.state

        return (
            <div className="bg-alarm-form">
                <HeaderComponent url_before="/alarme-opcoes" img_url="/images/alarm.png" alt="ícone alarme" title="ALARME"></HeaderComponent>
                <Container maxWidth="md">
                    <div className="form-text">
                        <Container>
                            <Grid container justify="flex-start">
                                <Grid item xs={12} sm={5}>
                                    <span><b style={{ color: "#45b6a7" }}>Legal, vamos montar juntos </b>o seu sistema!</span>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <div className="form">
                        <Container>
                        <Grid container justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item xs={12}>
                                    <p className="question">Quantas portas e janelas você quer proteger?</p>
                                </Grid>
                        </Grid>
                            <Grid container justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item xs={12} md={6}>
                                    <FormItem name="PORTAS" width={"250px"} color={"#3ba997"} field={"doors"} value={doors} onChange={this.changeFormItem} limit={64}/>
                                    <FormItem name="JANELAS" width={"250px"} color={"#319385"} field={"windows"} value={windows} onChange={this.changeFormItem} limit={64}/>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item xs={12} sm={9}>
                                    <p className="question">Quantos ambientes internos você quer proteger utilizando sensores de presença?</p>
                                    <p>Nesse caso, além do sensor no ponto de acesso, teremos também sensores varrendo o ambiente como um todo!</p>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <FormItem name="SENSORES" width={"250px"} field={"sensors"} value={sensors} onChange={this.changeFormItem} limit={64}/>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container justify="flex-start" alignItems="center">
                                <Grid item xs={12} sm={9}>
                                    <p className="question">Quantos metros quadrados aproximados terá a área que será protegida?</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" alignItems="stretch" spacing={3} style={{ marginTop: "8px" }}>
                                <Grid item xs={12} sm={5}>
                                    <div className={meters === 1 ? 'option-meters active' : 'option-meters'} onClick={() => this.changeMetersValue(1) } >
                                        0 A 200 METROS
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div className={meters === 2 ? 'option-meters active' : 'option-meters'} onClick={() => this.changeMetersValue(2)}>
                                        200 A 400 METROS
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div className={meters === 3 ? 'option-meters active' : 'option-meters'} onClick={() => this.changeMetersValue(3)}>
                                        400 A 600 METROS
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div className={meters === 4 ? 'option-meters active' : 'option-meters'} onClick={() => this.changeMetersValue(4)}>
                                        DE 600 PARA CIMA
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container justify="flex-start" alignItems="center">
                                <Grid item xs={12} sm={9}>
                                    <p className="question">Todo alarme funciona através de uma comunicação. Qual você prefere utilizar?</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" alignItems="stretch" spacing={3} style={{ marginTop: "8px" }}>
                                    <Grid item xs={12} sm={6}>
                                        <div className={comunication === 1 ? 'option active' : 'option'} onClick={() => this.changeComunicationValue(1)}>
                                            LINHA TELEFÔNICA DO LOCAL
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={comunication === 2 ? 'option active' : 'option'} onClick={() => this.changeComunicationValue(2)}>
                                            INTERNET DO LOCAL
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={comunication === 3 ? 'option active' : 'option'} onClick={() => this.changeComunicationValue(3)}>
                                            REDE DE RÁDIOS DIVS SEGURANÇA
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={comunication === 4 ? 'option active' : 'option'} onClick={() => this.changeComunicationValue(4)}>
                                            GPRS - CHIP DIVS
                                        </div>
                                    </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item xs={12} sm={9}>
                                    <p className="question">Além dos itens essenciais para a proteção, como central, sensores e forma de comunicação. Temos alguns acessórios para um sistema ainda mais completo! Selecione a quantidade de cada item desejado.</p>
                                    <FormItem name="TECLADO" field={"keyboard"} value={keyboard} onChange={this.changeFormItem} limit={16}/>
                                    <FormItem name="CONTROLE REMOTO PARA ARME E DESARME" color={"#319486"} field={"control"} value={control} onChange={this.changeFormItem} limit={96}/>
                                    {[2, 4].includes(comunication) && <FormItem name="APP PARA ATIVAÇÃO E DESATIVAÇÃO DO SISTEMA" color={"#277b72"} field={"app"} value={app} onChange={this.changeFormItem}/>}
                                    <FormItem name="SIRENE" color={"#1e6761"} field={"siren"} value={siren} onChange={this.changeFormItem} limit={16}/>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container justify="flex-start" alignItems="center">
                                <Grid item xs={12} sm={6}>
                                        <Link to='./alarme-obrigado'>
                                            <div onClick={ this.generateBudgetMessage } className="send-form">
                                                GERAR ORÇAMENTO
                                            </div>
                                        </Link>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container>
                                <Grid item>
                                    <img className="vs-logo" alt="logo vs" src="/images/vs-logo.png"/>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Container>
            </div>
        );
    }
}

export default AlarmForm;