import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

function HeaderComponent (props) {
    return <div style={{ paddingTop:"32px", marginBottom:"64px" }}>
                <Container>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item className="back-item">
                            <Link to="/">
                                <img src="/images/go-back.png" alt="voltar" style={{ height:"15px" }}></img>
                            </Link>
                            <Link to={props.url_before}>
                                <span style={{ color:"#F9F9F9", marginLeft:"8px", fontWeight:"200", fontSize:"14pt",  }}>VOLTAR</span>
                            </Link>
                        </Grid>
                        <Grid item sm={'auto'} className="button-header">
                            <Button disableRipple={true} style={{ color:"#2f3236",  backgroundColor:"#ffffff", borderRadius:"25px", fontSize:"10pt", height:"100%", padding:"10px 24px", marginLeft:"0px" }}>
                                <img src={props.img_url} alt={props.alt} height="21px" style={{ marginRight:"8px" }}/>
                                <b style={{ fontSize:"12pt" }}>{props.title}</b>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
}

export default HeaderComponent;