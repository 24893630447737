import React, { Component } from 'react';
import './Vehicle.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

class Vehicle extends Component {
    render() {
      return (
        <div className="bg-vehicle">
          <Container>
            <Grid container alignItems="center" justify="center" direction="column">
            <Grid item xs={12} sm={12}>
                <Button disabled={true} variant="outlined" disableRipple={true}>
                  <img src="/images/car.png" alt="icone carro" className="car-img" height="28px"/>
                  <b>RASTREAMENTO VEICULAR</b>
                </Button >
              </Grid>
              <Grid item xs={12} sm={9}>
                <h2 className="vehicle">
                  O Sistema de Rastreamento Veicular da DIVS Segurança é <b style={{ color:"#45b6a7" }}>mais um serviço oferecido </b>pela nossa empresa que permite a autogestão. 
                </h2>
              </Grid>
              <Grid item xs={12}>
                <Link to="/rastreamento-veicular-info">
                  <Button className="more-info">
                    SAIBA MAIS
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
  }
  export default Vehicle;