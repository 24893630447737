import React, { Component } from 'react';
import './Alarm-wpp.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import whatsappUrl from 'whatsapp-url';
import HeaderComponent from './HeaderComponent'


class AlarmOptions extends Component {
    render() {
      return ( 
        <div className="bg-alarm-options">
                <HeaderComponent url_before="/alarme-opcoes" img_url="/images/alarm.png" alt="ícone alarme" title="ALARME"></HeaderComponent>
            <div className="option-text">
                <Container>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} sm={5} >
                            <span><b style={{ color: "#45b6a7" }}>Obrigada por nos escolher!</b><br></br>Envie uma mensagem no Whatsapp para marcar um horário.</span>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="buttons">
                <Container>
                    <Grid container justify="center" alignItems="center" spacing={5}>
                        <Grid item xs={12} sm={3}>
                            <a href={whatsappUrl({phone: process.env.REACT_APP_PHONE_NUMBER, text: 'Olá, gostaria de receber um consultor de segurança na minha casa.'})} rel="noopener noreferrer" target="_blank">
                                <Button fullWidth>
                                <WhatsAppIcon></WhatsAppIcon>&nbsp;enviar mensagem
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
      );
    }
  }
  export default AlarmOptions;