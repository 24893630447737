import React, { Component } from 'react';
import './Street.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

class Street extends Component {
    render() {
      return (
        <div className="bg-street">
          <Container>
            <Grid container alignItems="center" justify="center" direction="column">
            <Grid item xs={12} sm={12}>
                <Button disabled={true} variant="outlined" disableRipple={true}>
                  <img src="/images/camera-rua.png" alt="icone camera" className="alarm-img" height="21px"/>
                  <b>RUA MONITORADA</b>
                </Button >
              </Grid>
              <Grid item xs={9} sm={9}>
                <h2 className="street">
                O serviço de Rua monitorada da DIVS Segurança consiste na <b style={{ color:"#45b6a7" }}>instalação de câmeras</b> ao longo de uma rua, conjunto ou quadra. 
                </h2>
              </Grid>
              <Grid item xs={12}>
                <Link to="/rua-monitorada-info">
                  <Button className="more-info">
                    SAIBA MAIS
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
  }
  export default Street;
   