import React, { Component } from 'react';
import './Vehicle-thanks.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import HeaderComponent from './HeaderComponent'

class VehicleThanks extends Component {
    render() {
      return (
        <div className="bg-vehicle-thanks">
            <div className="header">
                <HeaderComponent url_before="/rastreamento-veicular-info" img_url="/images/car.png" alt="ícone carro" title="RASTREAMENTO VEICULAR"></HeaderComponent>
            </div>
            <Container maxWidth="md">
                <div className="vehicle-text">
                    <Container>
                        <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <span><b style={{ color: "#45b6a7" }}>Obrigada por nos escolher!</b></span>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <span>Em breve nossa equipe irá responder você.</span>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Container>
        </div>
      );
    }
  }
  export default VehicleThanks;