import React, { Component } from 'react';
import './CFTV-form.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import whatsappUrl from 'whatsapp-url';
import HeaderComponent from './HeaderComponent';
import FormItem from './FormItem'


class CFTVform extends Component {
    state = {
        intern:0,
        extern:0,
        days:15,
        link:''
    }

    generateBudget = () => {
        const { intern, extern, days } = this.state
        let price = intern * 315.38
        price += extern * 334.04
        price += ((intern + extern) * 7.31)
        if (days === 15) {
            price += 748.35
        }
        if (days === 30 && (intern + extern) <= 8) {
            price += 748.35
        }
        if (days === 30 && (intern + extern) > 8) {
            price += 1046.86
        }
        if (days === 60 && (intern + extern) <= 8) {
            price += 1046.86
        }
        if (days === 60 && (intern + extern) > 8) {
            price += 1366.19
        }

        // DVR

        if ((intern + extern) <= 4) {
            price += 955.69
        }
        if ((intern + extern) > 4 && (intern + extern) <= 8) {
            price += 1313.00
        }
        if ((intern + extern) > 8) {
            price += 1953.63
        }

        // NOBREAK

        if ((intern + extern) <= 8) {
            price += 683.68
        }

        if ((intern + extern) > 8) {
            price += 1338.96
        }

        // FONTE 10A

        price += 239.04

        // CABO COAXIAL

        price += (intern + extern) * 50 * 1.72

        return price.toFixed(2)
    }

    generateBudgetMessage = () => {
        const {intern, extern, days} = this.state

        let message = 'Orçamento para CFTV'
        message += '%0ACâmeras para ambiente interno: ' + intern
        message += '%0ACâmeras para ambiente externo: ' + extern
        message += '%0ADias de gravação para manter: ' + days

        const price = this.generateBudget()

        message += '%0AValor total: R$ ' + price

        const link = whatsappUrl({phone: process.env.REACT_APP_PHONE_NUMBER, text: message})
        window.open(link)
        }

        changeFormItem = (field, value) => {
            this.setState(state => {
                return {
                    [field]: value
                }
            })
        }

    changeDaysValue = (value) => {
        this.setState({
            days: value
        })
    }

    render() {
      const { intern, extern, days } = this.state
      return (
        <div className="bg-cftv-form">
            <HeaderComponent url_before="/" img_url="/images/camera.png" alt="ícone camera" title="CFTV"></HeaderComponent>
            <Container maxWidth="md">
                <div className="form-text">
                    <Container>
                        <Grid container justify="flex-start">
                            <Grid item xs={12}>
                                <span><b style={{ color: "#45b6a7" }}>Para começar, todo sistema precisa de um DVR, </b>com HD para armazenamento e gerenciamento das imagens!</span>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="form">
                    <Container>
                    <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={12}>
                                <p className="question">Quantas câmeras gravando os ambientes você precisa?</p>
                            </Grid>
                    </Grid>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={12}>
                                <FormItem name="AMBIENTES INTERNOS" width={"250px"} field={"intern"} value={intern} onChange={this.changeFormItem}/>
                                <FormItem name="AMBIENTES EXTERNOS" width={"250px"} color={"#319285"} field={"extern"} value={extern} onChange={this.changeFormItem}/>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={12}>
                                <p className="question">Quantos dias de gravação você quer manter?</p>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <div className={days === 15 ? 'option-days active' : 'option-days'} onClick={() => this.changeDaysValue(15)}>
                                    15 DIAS
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={days === 30 ? 'option-days active' : 'option-days'} onClick={() => this.changeDaysValue(30)}>
                                    30 DIAS
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={days === 60? 'option-days active' : 'option-days'} onClick={() => this.changeDaysValue(60)}>
                                    60 DIAS
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Link to="./cftv-obrigado">
                                    <div onClick={ this.generateBudgetMessage } className="send-form">
                                        GERAR ORÇAMENTO
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid container>
                            <Grid item>
                                <img className="vs-logo" alt="logo vs" src="/images/vs-logo.png"/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Container>
        </div>
      );
    }
  }
  export default CFTVform;