import React, { Component } from 'react';
import './AlarmOptions.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import HeaderComponent from './HeaderComponent'

class AlarmOptions extends Component {
    render() {
      return ( 
        <div className="bg-alarm-options">
                <HeaderComponent url_before="/" img_url="/images/alarm.png" alt="ícone alarme" title="ALARME"></HeaderComponent>
            <div className="option-text">
                <Container>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} sm={7} >
                            <p>Você quer receber um consultor de segurança na sua casa ou quer montar o seu sistema por aqui?</p>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="buttons">
                <Container>
                    <Grid container justify="center" alignItems="center" spacing={5}>
                        <Grid item xs={12} sm={'auto'}>
                            <Link to="/alarme-form">
                                <Button fullWidth style={{ paddingLeft: 32, paddingRight: 32 }}>
                                MONTAR AGORA
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={'auto'}>
                            <Link to="/alarme-whatsapp">
                                <Button fullWidth style={{ paddingLeft: 32, paddingRight: 32 }}>
                                RECEBER EM CASA
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
      );
    }
  }
  export default AlarmOptions;