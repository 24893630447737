import React, { Component } from 'react';
import './Street-thanks.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import HeaderComponent from './HeaderComponent'


class StreetThanks extends Component {

    render() {
      return (
        <div className="bg-street-thanks">
             <HeaderComponent url_before="/rua-monitorada-info" img_url="/images/camera-rua.png" alt="ícone camera" title="RUA MONITORADA"></HeaderComponent>
            <Container maxWidth="md">
                <div className="street-text">
                    <Container>
                        <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <span><b style={{ color: "#45b6a7" }}>Obrigada por nos escolher!</b></span>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <span>Em breve nossa equipe irá responder você.</span>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Container>
        </div>
      );
    }
  }
  export default StreetThanks;